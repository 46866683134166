.content {
    &_brand {
        &_primary {
            &_dark {
                color: var(--content-brand-primary-dark) !important;
            }

            &_base {
                color: var(--content-brand-primary-base) !important;
            }

            &_light {
                color: var(--content-brand-primary-light) !important;
            }
        }

        &_secondary {
            &_dark {
                color: var(--content-brand-secondary-dark) !important;
            }

            &_base {
                color: var(--content-brand-secondary-base) !important;
            }

            &_light {
                color: var(--content-brand-secondary-light) !important;
            }
        }

        &_tertiary {
            &_dark {
                color: var(--content-brand-tertiary-dark) !important;
            }

            &_base {
                color: var(--content-brand-tertiary-base) !important;
            }

            &_light {
                color: var(--content-brand-tertiary-light) !important;
            }
        }

        &_quaternary {
            &_dark {
                color: var(--content-brand-quaternary-dark) !important;
            }

            &_base {
                color: var(--content-brand-quaternary-base) !important;
            }

            &_light {
                color: var(--content-brand-quaternary-light) !important;
            }
        }
    }

    &_anchor {
        &_dark {
            color: var(--content-anchor-dark) !important;
        }

        &_base {
            color: var(--content-anchor-base) !important;
        }

        &_light {
            color: var(--content-anchor-light) !important;
        }
    }

    &_neutral {
        &_dark {
            color: var(--content-neutral-dark) !important;
        }

        &_semi_dark {
            color: var(--content-neutral-semi-dark) !important;
        }

        &_base {
            color: var(--content-neutral-base) !important;
        }

        &_light {
            color: var(--content-neutral-light) !important;
        }

        &_white {
            color: var(--content-neutral-white) !important;
        }
    }

    &_state {
        &_success {
            &_dark {
                color: var(--content-state-success-dark) !important;
            }

            &_base {
                color: var(--content-state-success-base) !important;
            }

            &_light {
                color: var(--content-state-success-light) !important;
            }
        }

        &_warning {
            &_dark {
                color: var(--content-state-warning-dark) !important;
            }

            &_base {
                color: var(--content-state-warning-base) !important;
            }

            &_light {
                color: var(--content-state-warning-light) !important;
            }
        }

        &_danger {
            &_dark {
                color: var(--content-state-danger-dark) !important;
            }

            &_base {
                color: var(--content-state-danger-base) !important;
            }

            &_light {
                color: var(--content-state-danger-light) !important;
            }
        }

        &_info {
            &_dark {
                color: var(--content-state-info-dark) !important;
            }

            &_base {
                color: var(--content-state-info-base) !important;
            }

            &_light {
                color: var(--content-state-info-light) !important;
            }
        }
    }
}