.surface {
    &_brand {
        &_primary {
            &_dark {
                background-color: var(--surface-brand-primary-dark) !important;
            }

            &_semi_dark {
                background-color: var(--surface-brand-primary-semi-dark) !important;
            }

            &_base {
                background-color: var(--surface-brand-primary-base) !important;
            }

            &_light {
                background-color: var(--surface-brand-primary-light) !important;
            }
        }

        &_secondary {
            &_dark {
                background-color: var(--surface-brand-secondary-dark) !important;
            }

            &_semi_dark {
                background-color: var(--surface-brand-secondary-semi-dark) !important;
            }

            &_base {
                background-color: var(--surface-brand-secondary-base) !important;
            }

            &_light {
                background-color: var(--surface-brand-secondary-light) !important;
            }
        }

        &_tertiary {
            &_dark {
                background-color: var(--surface-brand-tertiary-dark) !important;
            }

            &_semi_dark {
                background-color: var(--surface-brand-tertiary-semi-dark) !important;
            }

            &_base {
                background-color: var(--surface-brand-tertiary-base) !important;
            }

            &_light {
                background-color: var(--surface-brand-tertiary-light) !important;
            }
        }

        &_quaternary {
            &_dark {
                background-color: var(--surface-brand-quaternary-dark) !important;
            }

            &_semi_dark {
                background-color: var(--surface-brand-quaternary-semi-dark) !important;
            }

            &_base {
                background-color: var(--surface-brand-quaternary-base) !important;
            }

            &_light {
                background-color: var(--surface-brand-quaternary-light) !important;
            }
        }
    }

    &_anchor {
        &_dark {
            background-color: var(--surface-anchor-dark) !important;
        }

        &_semi_dark {
            background-color: var(--surface-anchor-semi-dark) !important;
        }

        &_base {
            background-color: var(--surface-anchor-base) !important;
        }

        &_light {
            background-color: var(--surface-anchor-light) !important;
        }
    }

    &_neutral {
        &_dark {
            background-color: var(--surface-neutral-dark) !important;
        }

        &_semi_dark {
            background-color: var(--surface-neutral-semi-dark) !important;
        }

        &_base {
            background-color: var(--surface-neutral-base) !important;
        }

        &_light {
            background-color: var(--surface-neutral-light) !important;
        }
    }

    &_state {
        &_success {
            &_dark {
                background-color: var(--surface-state-success-dark) !important;
            }

            &_semi_dark {
                background-color: var(--surface-state-success-semi-dark) !important;
            }

            &_base {
                background-color: var(--surface-state-success-base) !important;
            }

            &_light {
                background-color: var(--surface-state-success-light) !important;
            }
        }

        &_warning {
            &_dark {
                background-color: var(--surface-state-warning-dark) !important;
            }

            &_semi_dark {
                background-color: var(--surface-state-warning-semi-dark) !important;
            }

            &_base {
                background-color: var(--surface-state-warning-base) !important;
            }

            &_light {
                background-color: var(--surface-state-warning-light) !important;
            }
        }

        &_danger {
            &_dark {
                background-color: var(--surface-state-danger-dark) !important;
            }

            &_semi_dark {
                background-color: var(--surface-state-danger-semi-dark) !important;
            }

            &_base {
                background-color: var(--surface-state-danger-base) !important;
            }

            &_light {
                background-color: var(--surface-state-danger-light) !important;
            }
        }

        &_info {
            &_dark {
                background-color: var(--surface-state-info-dark) !important;
            }

            &_semi_dark {
                background-color: var(--surface-state-info-semi-dark) !important;
            }

            &_base {
                background-color: var(--surface-state-info-base) !important;
            }

            &_light {
                background-color: var(--surface-state-info-light) !important;
            }
        }
    }
}

.svg_surface {
    &_neutral {

        &_semi_dark {
            fill: var(--surface-neutral-semi-dark) !important;

            & * {
                fill: var(--surface-neutral-semi-dark) !important;
            }
        }
    }
}