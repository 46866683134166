.rafErrorBoundary.error-container {
    text-align: center;
    margin: 50px auto;
    padding: 20px;
    max-width: 400px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.rafErrorBoundary .error-icon {
    color: red;
    font-size: 24px;
    margin-bottom: 10px;
}

.rafErrorBoundary .error-heading {
    font-size: 18px;
    font-weight: bold;
}

.rafErrorBoundary .error-subtext {
    font-size: 14px;
    color: #777;
    margin-bottom: 20px;
}

.rafErrorBoundary .report-link {
    color: #007bff;
    text-decoration: underline;
    display: block;
    margin-bottom: 20px;
}

.rafErrorBoundary .return-button {
    // background-color: #007bff;
    // color: #fff;
    // border: none;
    // padding: 10px 20px;
    // border-radius: 5px;
    // cursor: pointer;
}