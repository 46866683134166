//custom-treeview style start
.custom-treeview.e-treeview {
    &.fixedTemplate {
        & .e-text-content {
            // padding-left: 0;

            & .e-list-icon {
                margin-left: 0;
                display: none;
            }
        }
    }

    & .e-ul {
        padding: 0 0 0 0.625rem;
        overflow: hidden;
        margin: 0;

        & .e-list-item {
            padding: unset;

            &>.e-fullrow {
                height: 48px;
            }

            &>.e-text-content {
                height: 48px;

                .e-list-text {
                    padding: 0.5rem 0.5rem 0.5rem 0;
                }
            }

        }
    }

    &.raf-sm {
        & .e-ul .e-list-item {
            &>.e-fullrow {
                height: 40px;
                border-bottom: 1px solid #ddd;
            }

            &.e-active.e-hover>.e-fullrow,
            &.e-active.e-node-focus>.e-fullrow {
                border-color: #0078d6;
            }

            &>.e-text-content {
                height: 40px;
                display: flex;
                align-items: center;
                flex-wrap: nowrap;

                & .e-list-icon {
                    position: absolute;
                    left: 0;
                    top: 11px;
                }

                & .e-list-text {
                    height: 40px;
                    padding: 0;
                    width: 100%;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }

    &.book-treeview {

        & .e-ul {
            & .e-list-item {
                &>.e-fullrow {
                    border-bottom: 1px dashed transparent !important;
                }

                &.e-active {
                    &>.e-fullrow {
                        background-color: #f4f4f4;
                        border-color: #f4f4f4 !important;
                        border-left: 2px solid #0078d6 !important;
                        // border-bottom: 1px dashed #ddd !important;
                    }
                }
            }
        }

        &>.e-ul {
            padding-left: 0.25rem;
        }
    }
}

//custom-treeview style end

//List treeview style start
.listPage-treeview.e-treeview {
    & .e-ul {

        & .e-list-item {
            background-color: #fff;

            &>.e-fullrow {
                height: 60px;
                border-bottom: 1px solid #e0e0e0;
                background-color: #fff;
            }

            &>.e-text-content {
                height: 60px;
                display: flex;
                align-items: center;

                .e-list-text {
                    padding: 0.25rem 1rem 0.25rem 0;
                    width: 100%;
                }

                & .hover-child-div {
                    display: none;
                }

                & .hover-none-child-div {
                    display: block;
                }

                & .hover-show-child-div {
                    visibility: hidden;
                }

                & .hover-hide-child-div {
                    visibility: visible;
                }
            }

            &.e-hover {
                &>.e-fullrow {
                    background-color: #dcecf9;
                }

                &>.e-text-content {

                    & .hover-child-div {
                        display: block;
                    }

                    & .hover-none-child-div {
                        display: none;
                    }

                    & .hover-show-child-div {
                        visibility: visible;
                    }

                    & .hover-hide-child-div {
                        visibility: hidden;
                    }
                }
            }

            &.e-active {

                &.e-hover,
                &.e-node-focus {
                    &>.e-fullrow {
                        border: 1px solid #999;
                    }
                }
            }


        }
    }

    &.raf-sm {
        & .e-ul {

            & .e-list-item {

                &>.e-fullrow {
                    height: 3rem;
                }

                &>.e-text-content {
                    height: 3rem;
                }
            }
        }
    }
}


//List treeview style end