:root {
    --body-bg-color: #e1e2e3;
    --body-light-bg-color: #fafafa;
    --text-grey-color: #666;
    --text-block-color: #1d252c;
    --text-block-grey-color: #0C0C0E;
    --raf-text-success: #27AE60;

    --raf-primary: #0078d6;

    --border-block-grey-color: #333;
    --border-grey-color: #d3d7de;
    --border-light-grey-color: #e5e5e5;


    --raf-box-shadow-sm: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.075);
    --raf-box-shadow: 0px 0.25rem 0.3125rem 0px rgba(0, 0, 0, 0.02);

    --sjs-base-unit: 7px;
    --base-unit: 7px;

}

/* Primitives */

:root {

    //brand primary shades

    --primary-10: #E7F1FD;
    --primary-20: #D7E8FC;
    --primary-30: #BCD9FB;
    --primary-40: #9BC6F8;
    --primary-50: #7AB3F6;
    --primary-60: #58A0F4;
    --primary-70: #2E76CA;
    --primary-80: #255EA1;
    --primary-90: #1C4779;
    --primary-100: #122F51;

    //brand primary states

    --primary-base: #378DF2;
    --primary-focused: #D7E8FC;
    --primary-hover: #255EA1;
    --primary-pressed: #122F51;

    //brand secondary shades

    --secondary-10: #FEEFE6;
    --secondary-20: #FDDECD;
    --secondary-30: #FCC9AC;
    --secondary-40: #FAAD82;
    --secondary-50: #F99259;
    --secondary-60: #F77730;
    --secondary-70: #CD4D05;
    --secondary-80: #A43D04;
    --secondary-90: #7B2E03;
    --secondary-100: #521F02;

    //brand secondary states

    --secondary-base: #F65C06;
    --secondary-focused: #FDDECD;
    --secondary-hover: #A43D04;
    --secondary-pressed: #521F02;

    //brand tertiary shades

    --tertiary-10: #EBE7FD;
    --tertiary-20: #DED7FC;
    --tertiary-30: #C8BDFA;
    --tertiary-40: #AD9CF7;
    --tertiary-50: #927BF5;
    --tertiary-60: #765AF2;
    --tertiary-70: #4C30C8;
    --tertiary-80: #3D26A0;
    --tertiary-90: #2E1D78;
    --tertiary-100: #1E1350;

    //brand tertiary states

    --tertiary-base: #5B39F0;
    --tertiary-focused: #DED7FC;
    --tertiary-hover: #3D26A0;
    --tertiary-pressed: #1E1350;


    //brand quaternary shades

    --quaternary-10: #FEF8E6;
    --quaternary-20: #FDF2CE;
    --quaternary-30: #FCE9AD;
    --quaternary-40: #FBDD83;
    --quaternary-50: #FAD25A;
    --quaternary-60: #F8C731;
    --quaternary-70: #CE9D07;
    --quaternary-80: #A57D05;
    --quaternary-90: #7C5E04;
    --quaternary-100: #523F03;

    //brand quaternary states

    --quaternary-base: #F7BC08;
    --quaternary-focused: #FDF2CE;
    --quaternary-hover: #A57D05;
    --quaternary-pressed: #523F03;

    //anchor shades

    --anchor-10: #F0F1F5;
    --anchor-20: #E0E3EB;
    --anchor-30: #CCD0DD;
    --anchor-40: #ABB1C6;
    --anchor-50: #8089A9;
    --anchor-60: #56628D;
    --anchor-70: #2C3B71;
    --anchor-80: #021146;
    --anchor-90: #010D38;
    --anchor-100: #010A2A;

    //anchor states

    --anchor-base: #021454;
    --anchor-focused: #CCD0DD;
    --anchor-hover: #010D38;
    --anchor-pressed: #01071C;


    //neutral shades

    --neutral-10: #FFFFFF;
    --neutral-20: #F5F5F5;
    --neutral-30: #EEEEEE;
    --neutral-40: #E2E2E2;
    --neutral-50: #C5C5C5;
    --neutral-60: #A3A3A3;
    --neutral-70: #7C7C7D;
    --neutral-80: #686869;
    --neutral-90: #49494A;
    --neutral-100: #0C0C0E;

    //neutral states

    --neutral-base: #0C0C0E;
    --neutral-focused: #CECECF;
    --neutral-hover: #080809;
    --neutral-pressed: #040405;


    //status success shades

    --success-10: #ECF9EE;
    --success-20: #D9F2DE;
    --success-30: #D2F0D8;
    --success-40: #B4E6BF;
    --success-50: #8ED99E;
    --success-60: #68CD7E;
    --success-70: #43C05E;
    --success-80: #189634;
    --success-90: #137829;
    --success-100: #0F5A1F;

    //status success states

    --success-base: #1DB43E;
    --success-focused: #D2F0D8;
    --success-hover: #137829;
    --success-pressed: #0A3C15;


    //status warning shades

    --warning-10: #FEF3E1;
    --warning-20: #FDE2BA;
    --warning-30: #FBD397;
    --warning-40: #FAC575;
    --warning-50: #F9B653;
    --warning-60: #CF8C28;
    --warning-70: #A57020;
    --warning-80: #7C5418;
    --warning-90: #533810;
    --warning-100: #32220A;

    //status warning states

    --warning-base: #F8A830;
    --warning-focused: #FEEED6;
    --warning-hover: #A57020;
    --warning-pressed: #533810;


    //status danger shades

    --danger-10: #FEE1E1;
    --danger-20: #FDC3C3;
    --danger-30: #FCA5A5;
    --danger-40: #FC8888;
    --danger-50: #FB6A6A;
    --danger-60: #D03F3F;
    --danger-70: #A73333;
    --danger-80: #7D2626;
    --danger-90: #531919;
    --danger-100: #320F0F;

    //status danger states

    --danger-base: #FA4C4C;
    --danger-focused: #FEDBDB;
    --danger-hover: #A73333;
    --danger-pressed: #531919;


    //status info shades

    --info-10: #DCF1FE;
    --info-20: #C4E8FE;
    --info-30: #A7DCFD;
    --info-40: #8AD1FD;
    --info-50: #6CC5FC;
    --info-60: #429BD2;
    --info-70: #357CA8;
    --info-80: #285D7E;
    --info-90: #1A3E54;
    --info-100: #102532;

    //status info states

    --info-base: #4FBAFC;
    --info-focused: #DCF1FE;
    --info-hover: #357CA8;
    --info-pressed: #1A3E54;

    //tokens workesio

    // --------------> Content Brand Primary Start <------------------

    --content-brand-primary-dark: var(--primary-70);
    --content-brand-primary-base: var(--primary-base);
    --content-brand-primary-light: var(--primary-10);

    // --------------> Content Brand Primary End <------------------

    // --------------> Content Brand secondary Start <------------------

    --content-brand-secondary-dark: var(--secondary-70);
    --content-brand-secondary-base: var(--secondary-base);
    --content-brand-secondary-light: var(--secondary-10);

    // --------------> Content Brand secondary End <------------------

    // --------------> Content Brand tertiary Start <------------------

    --content-brand-tertiary-dark: var(--tertiary-70);
    --content-brand-tertiary-base: var(--tertiary-base);
    --content-brand-tertiary-light: var(--tertiary-10);

    // --------------> Content Brand tertiary End <------------------

    // --------------> Content Brand quaternary Start <------------------

    --content-brand-quaternary-dark: var(--quaternary-70);
    --content-brand-quaternary-base: var(--quaternary-base);
    --content-brand-quaternary-light: var(--quaternary-10);

    // --------------> Content Brand quaternary End <------------------

    // --------------> Content Anchor Start <------------------

    --content-anchor-dark: var(--anchor-70);
    --content-anchor-base: var(--anchor-base);
    --content-anchor-light: var(--anchor-10);

    // --------------> Content Anchor End <------------------

    // --------------> Content neutral Start <------------------

    --content-neutral-dark: var(--neutral-base);
    --content-neutral-semi-dark: var(--neutral-90);
    --content-neutral-base: var(--neutral-80);
    --content-neutral-light: var(--neutral-60);
    --content-neutral-white: var(--neutral-10);

    // --------------> Content neutral End <------------------

    // --------------> Content State Warning Start <------------------

    --content-state-warning-dark: var(--warning-70);
    --content-state-warning-base: var(--warning-base);
    --content-state-warning-light: var(--warning-10);

    // --------------> Content State Warning End <------------------

    // --------------> Content State Success Start <------------------

    --content-state-success-dark: var(--success-70);
    --content-state-success-base: var(--success-base);
    --content-state-success-light: var(--success-10);

    // --------------> Content State Success End <------------------

    // --------------> Content State Danger Start <------------------

    --content-state-danger-dark: var(--danger-70);
    --content-state-danger-base: var(--danger-base);
    --content-state-danger-light: var(--danger-10);

    // --------------> Content State Danger End <------------------

    // --------------> Content State Info Start <------------------

    --content-state-info-dark: var(--info-70);
    --content-state-info-base: var(--info-base);
    --content-state-info-light: var(--info-10);

    // --------------> Content State Info End <------------------

    // --------------> Surface Brand Primary Start <------------------

    --surface-brand-primary-dark: var(--primary-80);
    --surface-brand-primary-semi-dark: var(--primary-base);
    --surface-brand-primary-base: var(--primary-20);
    --surface-brand-primary-light: var(--primary-10);

    // --------------> Content Brand Primary End <------------------

    // --------------> Surface Brand Secondary Start <------------------

    --surface-brand-secondary-dark: var(--secondary-80);
    --surface-brand-secondary-semi-dark: var(--secondary-base);
    --surface-brand-secondary-base: var(--secondary-20);
    --surface-brand-secondary-light: var(--secondary-10);

    // --------------> Content Brand Secondary End <------------------

    // --------------> Surface Brand Tertiary Start <------------------

    --surface-brand-tertiary-dark: var(--tertiary-80);
    --surface-brand-tertiary-semi-dark: var(--tertiary-base);
    --surface-brand-tertiary-base: var(--tertiary-20);
    --surface-brand-tertiary-light: var(--tertiary-10);

    // --------------> Content Brand Tertiary End <------------------

    // --------------> Surface Brand Quaternary Start <------------------

    --surface-brand-quaternary-dark: var(--quaternary-80);
    --surface-brand-quaternary-semi-dark: var(--quaternary-base);
    --surface-brand-quaternary-base: var(--quaternary-20);
    --surface-brand-quaternary-light: var(--quaternary-10);

    // --------------> Content Brand Quaternary End <------------------

    // --------------> Surface anchor Start <------------------

    --surface-anchor-dark: var(--anchor-80);
    --surface-anchor-semi-dark: var(--anchor-base);
    --surface-anchor-base: var(--anchor-20);
    --surface-anchor-light: var(--anchor-10);

    // --------------> Content anchor End <------------------

    // --------------> Surface State Success Start <------------------

    --surface-state-success-dark: var(--success-80);
    --surface-state-success-semi-dark: var(--success-base);
    --surface-state-success-base: var(--success-20);
    --surface-state-success-light: var(--success-10);

    // --------------> Content State Success End <------------------

    // --------------> Surface State Warning Start <------------------

    --surface-state-warning-dark: var(--warning-80);
    --surface-state-warning-semi-dark: var(--warning-base);
    --surface-state-warning-base: var(--warning-20);
    --surface-state-warning-light: var(--warning-10);

    // --------------> Content State Warning End <------------------

    // --------------> Surface State Danger Start <------------------

    --surface-state-danger-dark: var(--danger-80);
    --surface-state-danger-semi-dark: var(--danger-base);
    --surface-state-danger-base: var(--danger-20);
    --surface-state-danger-light: var(--danger-10);

    // --------------> Content State Danger End <------------------

    // --------------> Surface State Info Start <------------------

    --surface-state-info-dark: var(--info-80);
    --surface-state-info-semi-dark: var(--info-base);
    --surface-state-info-base: var(--info-20);
    --surface-state-info-light: var(--info-10);

    // --------------> Content State Info End <------------------

    // --------------> Surface State Neutral Start <------------------

    --surface-neutral-dark: var(--neutral-base);
    --surface-neutral-semi-dark: var(--neutral-50);
    --surface-neutral-base: var(--neutral-20);
    --surface-neutral-light: var(--neutral-10);

    // --------------> Content State Neutral End <------------------


    // --------------> Font Size Start <------------------

    --header1-extra-bold: 800 3rem / 3.5rem Montserrat;
    --header1-bold: 700 3rem / 3.5rem Montserrat;
    --header1-semi-bold: 600 3rem / 3.5rem Montserrat;
    --header1-medium: 500 3rem / 3.5rem Montserrat;
    --header1-regular: 400 3rem / 3.5rem Montserrat;

    --header2-extra-bold: 800 2.5rem / 3rem Montserrat;
    --header2-bold: 700 2.5rem / 3rem Montserrat;
    --header2-semi-bold: 600 2.5rem / 3rem Montserrat;
    --header2-medium: 500 2.5rem / 3rem Montserrat;
    --header2-regular: 400 2.5rem / 3rem Montserrat;
    
    --header3-extra-bold: 800 2rem / 2.5rem Montserrat;
    --header3-bold: 700 2rem / 2.5rem Montserrat;
    --header3-semi-bold: 600 2rem / 2.5rem Montserrat;
    --header3-medium: 500 2rem / 2.5rem Montserrat;
    --header3-regular: 400 2rem / 2.5rem Montserrat;
    
    --header4-extra-bold: 800 1.5rem / 2rem Montserrat;
    --header4-bold: 700 1.5rem / 2rem Montserrat;
    --header4-semi-bold: 600 1.5rem / 2rem Montserrat;
    --header4-medium: 500 1.5rem / 2rem Montserrat;
    --header4-regular: 400 1.5rem / 2rem Montserrat;
    
    --header5-extra-bold: 800 1.25rem / 1.75rem Montserrat;
    --header5-bold: 700 1.25rem / 1.75rem Montserrat;
    --header5-semi-bold: 600 1.25rem / 1.75rem Montserrat;
    --header5-medium: 500 1.25rem / 1.75rem Montserrat;
    --header5-regular: 400 1.25rem / 1.75rem Montserrat;
    
    --header6-extra-bold: 800 1.125rem / 1.5rem Montserrat;
    --header6-bold: 700 1.125rem / 1.5rem Montserrat;
    --header6-semi-bold: 600 1.125rem / 1.5rem Montserrat;
    --header6-medium: 500 1.125rem / 1.5rem Montserrat;
    --header6-regular: 400 1.125rem / 1.5rem Montserrat;
    
    --subtitle1-extra-bold: 800 1rem / 1.5rem Montserrat;
    --subtitle1-bold: 700 1rem / 1.5rem Montserrat;
    --subtitle1-semi-bold: 600 1rem / 1.5rem Montserrat;
    --subtitle1-medium: 500 1rem / 1.5rem Montserrat;
    --subtitle1-regular: 400 1rem / 1.5rem Montserrat;

    --subtitle2-extra-bold: 800 0.875rem / 1.25rem Montserrat;
    --subtitle2-bold: 700 0.875rem / 1.25rem Montserrat;
    --subtitle2-semi-bold: 600 0.875rem / 1.25rem Montserrat;
    --subtitle2-medium: 500 0.875rem / 1.25rem Montserrat;
    --subtitle2-regular: 400 0.875rem / 1.25rem Montserrat;

    --subtitle3-extra-bold: 800 0.75rem / 1rem Montserrat;
    --subtitle3-bold: 700 0.75rem / 1rem Montserrat;
    --subtitle3-semi-bold: 600 0.75rem / 1rem Montserrat;
    --subtitle3-medium: 500 0.75rem / 1rem Montserrat;
    --subtitle3-regular: 400 0.75rem / 1rem Montserrat;

    --body1-extra-bold: 800 1rem / 1.5rem Roboto;
    --body1-bold: 700 1rem / 1.5rem Roboto;
    --body1-semi-bold: 600 1rem / 1.5rem Roboto;
    --body1-medium: 500 1rem / 1.5rem Roboto;
    --body1-regular: 400 1rem / 1.5rem Roboto;


    --body2-extra-bold: 800 0.875rem / 1.25rem Roboto;
    --body2-bold: 700 0.875rem / 1.25rem Roboto;
    --body2-semi-bold: 600 0.875rem / 1.25rem Roboto;
    --body2-medium: 500 0.875rem / 1.25rem Roboto;
    --body2-regular: 400 0.875rem / 1.25rem Roboto;

    --body3-extra-bold: 800 0.75rem / 1rem Roboto;
    --body3-bold: 700 0.75rem / 1rem Roboto;
    --body3-semi-bold: 600 0.75rem / 1rem Roboto;
    --body3-medium: 500 0.75rem / 1rem Roboto;
    --body3-regular: 400 0.75rem / 1rem Roboto;

    // --------------> Font Size End <------------------

}

// border color variables 

:root {
    --stroke-gray: var(--neutral-30);
    --stroke-dark-gray: var(--neutral-50);
    --stroke-white: var(--neutral-10);
    --stroke-anchor: var(--anchor-base);
    --stroke-neutral: var(--neutral-base);
    --stroke-neutral-dark: var(--neutral-50);
    --stroke-neutral-base: var(--neutral-40);
    --stroke-neutral-light: var(--neutral-20);
    --stroke-primary: var(--primary-base);
    --stroke-secondary: var(--secondary-base);
    --stroke-tertiary: var(--tertiary-base);
    --stroke-success: var(--success-base);
    --stroke-warning: var(--warning-base);
    --stroke-danger: var(--danger-base);
    --stroke-info: var(--info-base);

    --box-shadow-lg: 0px 0.625rem 3rem 0px rgba(12, 12, 14, 0.05);
}