button.e-btn {

    &.sky_primary_outline_button {
        color: #29ABE2;
        border: 1px solid #29ABE2;
        background-color: #fff;

        &:hover {
            background-color: #29ABE2;
            color: #fff;

        }
    }

    &.sky_primary_solid_button {
        color: #fff;
        border: 1px solid #29ABE2;
        background-color: #29ABE2;

        &:hover {
            background-color: var(--surface-brand-primary-dark);
            border: 1px solid var(--stroke-primary);
        }
    }

    &.sky_success_solid_button {
        color: #fff;
        border: 1px solid #35C55B;
        background-color: #35C55B;

        &:hover {
            border: 1px solid var(--stroke-success);
            background-color: var(--surface-state-success-dark);
        }
    }
}