.pagePrimaryHeader {
    background-color: #dcecf9;
    padding: 0.5rem;
    border-radius: 0.5rem;

    &.white {
        background-color: #fff;
        border: 1px solid #eaeaea;
    }
}

.cardContainer {


    &__btnContainer {
        // visibility: hidden;
        display: none;

        &__absolute {
            position: absolute;
            right: 1.75rem;
            bottom: 0.5rem;
            background-color: #fff;
            z-index: 100;

            &__bottom {
                position: absolute;
                right: 1.75rem;
                background-color: #fff;
                z-index: 100;
                bottom: 0.375rem;
            }
        }
    }

    &.e-hover {
        & .cardContainer__btnContainer {
            // visibility: visible;
            display: flex;
            flex-wrap: nowrap;
        }

        & .cardContainer {
            & .cardContainer__btnContainer {
                // visibility: hidden;
                display: none;
            }

            &.e-hover .cardContainer__btnContainer {
                // visibility: visible;
                display: flex;
            }
        }
    }
}

.cardPanel {
    // background-color: transparent !important;
    // border: unset !important;
    border-radius: 0.25rem;
    overflow: visible !important;
    padding-bottom: 0.75rem;

    &__header {
        font-size: 1.3125rem !important;
        font-weight: 700 !important;
        color: #333 !important;

        // border-bottom: 1px solid #b3b3b3;
        // padding-left: 0px !important;
        &__value[aria-placeholder]:empty:before {
            content: attr(aria-placeholder);
            color: var(--foreground-light, #a0a0a0);
        }
    }

    &__content {
        padding: 0 0.75rem 0.75rem;
    }

    &.e-hover {
        box-shadow: 0 0px 10px rgba(255, 152, 20, 0.5);
    }

    &.e-active {
        box-shadow: 0 0 0 2px #ff9814;
    }

    &__footer {
        background-color: #f5f9fa;
        padding: 0.375rem 0.75rem;
        // padding: 0.5rem 1rem;
        border-top: 1px solid #eaeaea;
    }
}

.cardSection {
    overflow: visible;

    &__header {
        font-size: 1.125rem !important;
        font-weight: 500 !important;
        color: #333 !important;
        border-bottom: 1px solid #ddd;
        min-height: 50px;

        &__value {
            &[aria-placeholder]:empty:before {
                content: attr(aria-placeholder);
                color: var(--foreground-light, #909090);
            }

            &__hidden {
                opacity: 0.4;
            }
        }
    }

    &__content {
        padding: 0.75rem;
    }

    &__inputItems {
        // border-top: 1px solid #dee2e6;
        // padding-top: 1rem;
        // padding-top: 0.5rem;
    }

    &.e-hover {
        box-shadow: 0 0px 10px rgba(255, 152, 20, 0.5);
    }

    &.e-active {
        box-shadow: 0 0 0 2px #ff9814;
    }


}

.showInputInCard .cardSection__inputItems {
    padding: 1rem !important;
    border: 1px solid #dee2e6 !important;
    border-radius: 0.3125rem;

}

.column-3-layout .duplicate-btn,
.column-2-layout .duplicate-btn {
    display: none;
}

.sectionColumn {
    border: 1px dashed #e3e3e7;
    border-radius: 4px;
    // box-shadow: 0 0 0 2px rgb(0 0 0 / 10%);
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
    padding: 0.5rem;

    // min-height: 100px;
    // &.e-hover {
    //     box-shadow: 0 0 0 2px #ff9814;
    // }
}

.sectionColumn-input {
    border: 1px dashed transparent;
    border-radius: 4px;
    // box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
    padding: 0.5rem;
    position: relative;

    // min-height: 100px;
    &__header {
        font-size: 10px !important;
        font-weight: 700;
        text-transform: uppercase;
        color: rgba(51, 51, 51, 0.8);
        line-height: normal;
    }

    & .hover-show-child-div {
        visibility: hidden;
    }


    &.e-hover {
        box-shadow: 0 0px 10px rgba(255, 152, 20, 0.5);

        & .hover-show-child-div {
            visibility: visible;
        }
    }

    &.e-active {
        box-shadow: 0 0 0 2px #ff9814;

        & .hover-show-child-div {
            visibility: visible;
        }
    }

    & .twoColLayoutPanel__left__inputLabel .form-label:empty:before {
        content: "Input field";
        color: var(--foreground-light, #909090);
    }

    &__relative {
        position: relative;
        // height: 34px;        // don't set the height for the element. It will break the ui in mobile responsive.
        display: flex;
        align-items: center;

        &__btnContainer {
            margin-top: 6px;
            right: 5px !important;
        }
    }

    &__btnContainer {
        position: absolute;
        right: 0;
        top: -6px;
    }

    & .bp-card-content {
        padding: 0 0.5rem 0.5rem;

        &-hidden {
            opacity: 0.4;

            &::after {
                content: "";
                position: absolute;
                left: -2px;
                top: 0;
                bottom: 0;
                border-left: 4px solid #ccc;
            }
        }
    }
}

.card-action-btn {
    display: none;
}

.cardContainer.e-active .card-action-btn {
    display: flex;
}

.card-label-panel,
.card-action-panel {
    position: absolute;
    top: -26px;
    left: 0px;
    height: 26px;
    line-height: 26px;
    display: none;
    align-items: center;
    justify-content: space-between;
    width: auto;
    // width: 100%;
    z-index: 1000;

    & .e-badge {
        background-color: #ff9814;
        color: #fff;
    }

    & button.e-btn:not(.unset-custom-button-md) {
        height: 26px !important;
        width: 26px !important;
        min-height: 26px !important;
        min-width: 26px !important;
        background-color: #ff9814 !important;
        color: #fff !important;

        & .e-btn-icon {
            color: #fff;
        }

    }

    & .col-auto:first-child {
        & button.e-btn:not(.unset-custom-button-md) {

            border-left-width: 0;
        }
    }

    & .col-auto:last-child {
        & button.e-btn:not(.unset-custom-button-md) {

            border-right-width: 0;
        }
    }

    & button.e-btn:not(.unset-custom-button-md):not(.e-icon-btn) {
        width: auto !important;
    }
}

.card-action-panel {
    justify-content: flex-end;
    top: -27px;
}

// .cardContainer.e-hover>.card-label-panel {
//     display: flex;
// }

.card-button-panel {
    // visibility: hidden;
    display: none;
    padding: 0.5rem 0;
}

.card-button-panel-default {
    visibility: hidden;
    display: flex;

    padding: 0.5rem 0;
}

.cardContainer {
    &.e-hover {

        .card-button-panel-default {
            visibility: visible;
            display: flex;

            padding: 0.5rem 0;
        }

        .cardContainer {

            .card-button-panel-default {
                visibility: hidden;
            }

            &.e-hover {

                .card-button-panel-default {
                    visibility: visible;
                }
            }
        }
    }

    &.e-active {

        &>.card-action-panel,
        &>.card-label-panel {
            display: flex;
        }

        & .card-button-panel {
            // visibility: visible;
            display: flex;
        }

        & .card-button-panel-default {
            // visibility: hidden;
            display: none;
        }

        & .cardContainer {

            & .card-button-panel {
                // visibility: hidden;
                display: none;
            }

            & .card-button-panel-default {
                // visibility: visible;
                display: flex;
            }

            &.e-active {

                & .card-button-panel {
                    // visibility: visible;
                    display: flex;

                }

                & .card-button-panel-default {
                    // visibility: hidden;
                    display: none;

                }
            }
        }
    }
}

.formLibrary__form>div>.row>.col-12 {
    &>div {
        padding: 2px;
    }

    &:first-child {

        & .e-card.bp-card.cardPanel.cardContainer {
            overflow: hidden !important;
            border: 0;
            padding: 2px;

            &>.card-label-panel {
                top: -2px;
                left: -2px;
                align-items: flex-start;

                &>div {
                    display: flex;
                    align-items: flex-start;
                }
            }

            &>.card-action-panel {
                top: -2px;
                right: -2px;
            }
        }
    }
}

.e-card.cardWithGreyBg {
    background-color: #f1f5fa;
    border: 2px solid #e8ecf0;
    padding: 0;

    &:hover {
        background-color: #f1f5fa;
        border: 2px solid #e8ecf0;
        padding: 0;
    }
}

.rafInput {
    &__Left {
        display: flex;
        align-items: center;
        padding-top: 0.5rem;

        &__label {
            width: 30%;
            padding-right: 0.5rem;

            &__container {
                height: 32px;
                display: flex;
                align-items: center;

                & .form-label {
                    line-height: 28px;
                    height: 32px;
                }
            }
        }
    }

    &__Right {
        display: flex;
        align-items: center;
        padding-top: 0.5rem;

        &__label {
            width: auto;
            order: 1;
            padding-left: 0.5rem;

            &__container {
                height: 32px;
                display: flex;
                align-items: center;

                & .form-label {
                    line-height: 28px;
                    height: 32px;
                }
            }
        }
    }

    &__Bottom {
        display: flex;
        flex-direction: column;
        padding-top: 0.5rem;

        &__label {
            order: 1;

            &__container {
                height: 32px;
                display: flex;
                align-items: center;

                & .form-label {
                    line-height: 32px;
                    height: 32px;
                }
            }
        }
    }
}

.CardLayout {
    &__container {
        padding: 0.125rem;
    }
}

.PageLayout {
    &__container {
        padding: 1rem;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    }

    &__cardPanel {
        background-color: transparent;
        border-color: transparent;
        border-left: 3px solid #bbb;
        border-radius: 0;
        position: relative;
        overflow: visible;

        &:hover,
        &:active {
            background-color: transparent;
            border-color: transparent;
            border-left: 3px solid #bbb;
        }

        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            border-bottom: 1px solid #bbb;
            top: -0.5rem;
        }
    }

    &__Start,
    &__End,
    &__child__0 {
        &::after {
            content: none;
        }
    }

    &__child:first-child .PageLayout__cardPanel {
        &::after {
            content: none;
        }
    }

}