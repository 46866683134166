.customQBWidth .e-group-body {
    padding-left: 0;
}

.customQBWidth .e-group-container {
    display: flex;
    flex-direction: column;
}

.customQBWidth .e-group-header {
    order: 1;
    margin-top: 1rem;
}

.customQBWidth .e-rule-field {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-top: 5px;
    padding-bottom: 5px;
}

.customQBWidth .e-rule-filter,
.customQBWidth .e-rule-operator,
.customQBWidth .e-rule-value {
    display: block !important;
    width: 100% !important;
}

.customQBWidth .e-rule-filter {
    order: 1;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
}

.customQBWidth .e-rule-value-delete button.e-rule-delete {
    margin-bottom: 0;
}

.customQBWidth .e-rule-operator {
    order: 3 !important;
}

.customQBWidth .e-rule-value {
    order: 4 !important;
}

.customQBWidth .e-rule-value-delete {
    order: 2;
    display: flex !important;
    align-items: center;
}

.customQBWidth.e-query-builder .e-rule-list> ::after,
.customQBWidth.e-query-builder .e-rule-list> ::before {
    display: none;
}