@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body,
body [class*=" raf-"],
body [class^=raf-] {
    font-family: Roboto, Helvetica, Arial, sans-serif !important;
}

.e-control,
.e-css,
.e-tab .e-tab-header .e-toolbar-item .e-tab-text,
.e-badge {
    font-family: Roboto, Helvetica, Arial, sans-serif !important;
}

// .fontFamily_Montserrat .e-control,
// .fontFamily_Montserrat .e-css,
// .fontFamily_Montserrat .e-tab .e-tab-header .e-toolbar-item .e-tab-text,
// .fontFamily_Montserrat .e-badge {
//     font-family: 'Montserrat' !important;
// }