.timeline-title {
    font-weight: 500;
    font-size: 13px;
    color: #333;
}

.timeline {

    &__li {
        padding: 1.25rem 0.25rem;
        position: relative;
        margin-bottom: 0;

        &::before {
            content: "";
            position: absolute;
            height: 100%;
            width: 1px;
            background: #eee;
            left: 2.75rem;
            top: 0;
        }
    }

    &__item {
        z-index: 1;
        position: relative;

        & .timeline__item__circle {
            margin-top: 0.25rem;

            &::before {
                content: "";
                width: 0.5625rem;
                height: 0.5625rem;
                background-color: #fff;
                border-radius: 50%;
                border: 0.0625rem solid var(--stroke-primary);
                display: flex;
            }
        }

        &__recordInfo.e-btn:not(.p-0) {
            height: auto !important;
            min-height: unset !important;
            padding: 0.125rem 0.75rem !important;
            line-height: normal !important;
            min-width: unset !important;
        }

        &__moveTop {
            position: relative;
            top: -6px;
        }
    }

    &__date {
        font-size: 0.8125rem;
        color: #1e1e1e;
        font-weight: 400;

        &__container {
            width: 2rem;
            min-width: 2rem;
            max-width: 2rem;
        }
    }

    &__time {
        font-size: 0.75rem;
        line-height: 1rem;
        color: var(--content-neutral-dark);
        font-weight: 500;
        width: 2rem;
        min-width: 2rem;
        max-width: 2rem;
        // white-space: nowrap;
        text-align: center;

        &__badge {
            width: 3.5rem;
            min-width: 3.5rem;
            max-width: 3.5rem;
            text-align: center;
            position: relative;
            left: -0.375rem;
        }
    }

    &__min {
        font-size: 0.75rem;
        font-weight: 400;
        color: #1e1e1e;
        text-align: center;
    }

    &__title {
        font-size: 0.875rem;
        color: #0078D7;
        font-weight: 500;

        &:hover {
            // text-decoration: underline;
        }
    }

    &__description {
        font-size: 0.875rem;
        color: #1e1e1e;
        font-weight: 500;
        padding: 0.5rem;
        /* border: 1px solid #ccc; */
        border-radius: 0.5rem;
        background-color: #f3f4f5;
    }

    &__ul {
        list-style: none;
        padding: 0;
        margin: 0;

        &__active {
            & .timeline__li.timeline__li__completed {
                &::before {
                    // background-color: var(--surface-brand-primary-semi-dark);
                    // background-color: #146511;
                }

                & .timeline__item .timeline__item__circle::before {
                    border: 0;
                    // background-color: #146511;
                    background-color: var(--surface-brand-primary-semi-dark);
                    box-shadow: 0 0 0 2px #fff;
                    color: #fff;
                    // content: "\f00c" !important;
                    font-family: var(--fa-style-family, "Font Awesome 6 Pro");
                    font-weight: 900 !important;
                    speak: none;
                    font-size: 10px;
                    font-style: normal;
                    font-feature-settings: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            &-li.timeline__li__required {
                &::before {
                    // background-color: #ff0000;
                }

                & .timeline__item .timeline__item__circle::before {
                    border-color: #ff0000;
                    background-color: #fff;
                    box-shadow: 0 0 0 2px #fff;
                }
            }
        }
    }

}

@media only screen and (min-width: 768px) {
    .timeline__li {
        padding: 0.5rem 1rem 0.5rem 0;

        &::before {
            left: 2.75rem;
        }
    }
}